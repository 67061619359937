import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
Vue.use(Router)
export const pcRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  {
    path: '/errorPage',
    hidden: true,
    component: resolve => require(['@/views/errorPage'], resolve),
    meta: {
      title: 'Error Page',
      icon: 'errorPage'
    }
  },
  {
    path: '',
    component: Layout,
    redirect: '/home',
    hidden: true,
    meta: { title: 'Home', icon: 'home', permissionId: '' },
    children: [{
      path: 'home',
      component: () => import('@/views/home'),
      name: 'Home',
      meta: { title: 'Home', icon: 'home', permissionId: '' }
    }]
  },
  {
    path: '/dataMgt',
    component: Layout,
    redirect: '/dataMgt/vehicle',
    name: '数据管理',
    meta: {
      title: '数据管理',
      icon: 'dataMgt',
      permissionId: 'vehicle'
    },
    children: [
      {
        path: 'vehicle',
        component: () => import('@/views/carDataMgt'),
        name: '车辆数据',
        meta: { title: '车辆数据', icon: '', permissionId: 'vehicle' }
      },
      {
        path: 'vehicle/edit',
        component: () => import('@/views/carDataMgt/edit'),
        name: '编辑车型',
        hidden: true,
        meta: {
          title: 'edit',
          icon: '',
          permissionId: '',
          parent: 'vehicle' // 三级菜单父级指向标识
        }
      },
      {
        path: 'vehicle/add',
        component: () => import('@/views/carDataMgt/add'),
        name: '添加车型',
        hidden: true,
        meta: {
          title: 'add',
          icon: '',
          permissionId: '',
          parent: 'vehicle' // 三级菜单父级指向标识
        }
      }
    ]
  },
  {
    path: '/lossMgt',
    component: Layout,
    redirect: '/lossMgt/lossPosition',
    name: '质损管理',
    meta: {
      title: '质损管理',
      icon: 'lossMgt'
    },
    children: [
      {
        path: 'lossPosition',
        component: () => import('@/views/lossMgt/lossPosition'),
        name: '质损部位',
        meta: { title: '质损部位', icon: '', permissionId: 'position' }
      },
      {
        path: 'lossArea',
        component: () => import('@/views/lossMgt/lossArea'),
        name: '质损区域',
        meta: { title: '质损区域', icon: '', permissionId: 'area' }
      },
      {
        path: 'lossPart',
        name: '质损零件',
        component: resolve =>
          require(['@/views/lossMgt/lossPart'], resolve),
        meta: {
          title: '质损零件',
          icon: '',
          permissionId: 'parts'
        }
      },
      {
        path: 'lossType',
        name: '质损类型',
        component: resolve =>
          require(['@/views/lossMgt/lossType'], resolve),
        meta: {
          title: '质损类型',
          icon: '',
          permissionId: 'type'
        }
      },
      {
        path: 'lossDegree',
        name: '质损程度',
        component: resolve =>
          require(['@/views/lossMgt/lossDegree'], resolve),
        meta: {
          title: '质损程度',
          icon: '',
          permissionId: 'severity'
        }
      }
    ]
  },
  {
    path: '/questionMgt',
    component: Layout,
    redirect: '/questionMgt/question',
    name: '题库管理',
    meta: {
      title: '题库管理',
      icon: 'questionMgt'
    },
    children: [
      {
        path: 'question',
        component: () => import('@/views/questionMgt/question'),
        name: '题目管理',
        meta: { title: '题目管理', icon: '', permissionId: 'question' }
      },
      {
        path: 'question/add',
        component: () => import('@/views/questionMgt/question/add'),
        name: '添加题目',
        hidden: true,
        meta: {
          title: 'add',
          icon: '',
          permissionId: 'question',
          parent: 'question' // 三级菜单父级指向标识
        }
      },
      {
        path: 'question/edit',
        component: () => import('@/views/questionMgt/question/edit'),
        name: '编辑题目',
        hidden: true,
        meta: {
          title: 'add',
          icon: '',
          permissionId: 'question',
          parent: 'question' // 三级菜单父级指向标识
        }
      },
      {
        path: 'version',
        name: '版本管理',
        component: resolve =>
          require(['@/views/questionMgt/version'], resolve),
        meta: {
          title: '版本管理',
          icon: '',
          permissionId: 'version'
        }
      },
      {
        path: 'version/add',
        component: () => import('@/views/questionMgt/version/add'),
        name: '添加版本',
        hidden: true,
        meta: {
          title: 'add',
          icon: '',
          permissionId: 'version',
          parent: 'version' // 三级菜单父级指向标识
        }
      },
      {
        path: 'version/edit',
        component: () => import('@/views/questionMgt/version/edit'),
        name: '编辑版本',
        hidden: true,
        meta: {
          title: 'add',
          icon: '',
          permissionId: 'version',
          parent: 'version' // 三级菜单父级指向标识
        }
      },
      {
        path: 'version/copy',
        component: () => import('@/views/questionMgt/version/copy'),
        name: '复制版本',
        hidden: true,
        meta: {
          title: 'add',
          icon: '',
          permissionId: 'version',
          parent: 'version' // 三级菜单父级指向标识
        }
      }
    ]
  },
  {
    path: '/dashboard',
    component: Layout,
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
      icon: 'dashboard'
    },
    children: [
      {
        path: 'rate',
        component: () => import('@/views/dashboard/pdiOkRate'),
        name: 'PDI OK rate',
        meta: { title: 'PDI OK rate', icon: '', permissionId: 'dashboard' }
      },
      {
        path: 'damage',
        component: () => import('@/views/dashboard/damage'),
        name: 'Damage',
        meta: { title: 'Damage', icon: '', permissionId: 'dashboard' }
      },
      {
        path: 'trend',
        component: () => import('@/views/dashboard/trend'),
        name: 'Trend',
        meta: { title: 'Trend', icon: '', permissionId: 'dashboard' }
      },
      {
        path: 'analysis',
        component: () => import('@/views/dashboard/questionAnalysis'),
        name: 'Question Analysis',
        meta: { title: 'Question Analysis', icon: '', permissionId: 'dashboard' }
      }
    ]
  },
  {
    path: '/pdiReport',
    component: Layout,
    parentHidden: true, // 只想展示一层菜单时的标识
    meta: {
      title: 'PDI Report',
      icon: 'report',
      permissionId: 'report'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/report'),
        name: 'PDI Report',
        meta: { title: 'PDI Report', icon: '', permissionId: 'report' }
      }
    ]
  },
  {
    path: '/lossDetail',
    component: Layout,
    redirect: '/lossDetail/list',
    name: 'PDI详情',
    meta: {
      title: 'PDI详情',
      icon: 'lossDetail',
      permissionId: 'detail'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/lossDetail'),
        name: 'PDI结果查询',
        meta: { title: 'PDI结果查询', icon: '', permissionId: 'detail' }
      },
      {
        path: 'pdi',
        component: () => import('@/views/lossDetail/pdi'),
        name: 'PDI详情',
        hidden: true,
        meta: {
          title: 'PDI详情',
          icon: '',
          permissionId: 'detail',
          parent: 'list' // 三级菜单父级指向标识
        }
      },
      {
        path: 'loss',
        component: () => import('@/views/lossDetail/loss'),
        name: '质损详情',
        hidden: true,
        meta: {
          title: '质损详情',
          icon: '',
          permissionId: 'detail',
          parent: 'list' // 三级菜单父级指向标识
        }
      }
    ]
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting/permission',
    name: '设置',
    meta: {
      title: '设置',
      icon: 'setting',
      permissionId: 'user'
    },
    children: [
      {
        path: 'permission',
        component: () => import('@/views/permission'),
        name: '权限管理',
        meta: { title: '权限管理', icon: '', permissionId: 'user' }
      },
      {
        path: 'permission/edit',
        component: () => import('@/views/permission/edit'),
        name: '编辑账号',
        hidden: true,
        meta: {
          title: 'edit',
          icon: '',
          permissionId: 'user',
          parent: 'permission' // 三级菜单父级指向标识
        }
      }
    ]
  }
]

export const constantRouterMap = pcRouterMap

const createRouter = routeMap =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0
    }),
    routes: routeMap
  })

const router = createRouter(constantRouterMap)

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
